<template>
  <v-dialog v-model="dialog" persistent max-width="430px">
    <v-card class="category-modal">
      <v-card-title>
        <v-btn fab dark color="info" x-small text @click="$emit('closeModal')">
          <IconPictoAarrowsLeft />
        </v-btn>
        <span class="category-modal__title">{{ title }}</span>
        <v-btn fab dark color="info" x-small text @click="$emit('closeModal')">
          <v-icon dark large> mdi-window-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="category-modal__body">
          <div class="category-list">
            <div
              v-for="(cat, catIndex) in categories"
              :key="`subcat-${catIndex}`"
              class="category-list__item"
            >
              <div
                class="category-list__item--img"
                :style="styleItem(cat.image)"
              ></div>
              <div class="category-list__item--text">{{ cat.name }}</div>
              <div class="category-list__item--checkbox">
                <v-checkbox
                  v-model="selected"
                  :value="cat.id"
                  on-icon="mdi-plus-box-outline"
                  off-icon="mdi-plus-box-outline"
                >
                </v-checkbox>
              </div>
            </div>
          </div>
          <div class="text-center">
            <div v-if="categories.length === 0" class="error--text">
              Pas de catégories disponibles pour cette piece  
            </div>
            <v-btn
              v-else
              color="info"
              depressed
              @click="createRoomCategory"
              :disabled="selected.length === 0"
              :loading="loading"
            >
              AJOUTER LES PRESTATIONS
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { CategoryService } from "@/services/category.service.js";

import { pathImage } from "@/utils/index.js";
export default {
  name: "CategoryListDeepTwoModal",
  props: {
    title: {
      type: String,
      default: () => "Title",
    },
    categories: {
      type: Array,
      required: true,
    },
    roomQuotaCategories: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
    },
    roomId: {
      type: Number,
      required: true,
    },
  },
  components: {
    IconPictoAarrowsLeft: () => import("~cp/Icons/IconPictoAarrowsLeft"),
  },
  data() {
    return {
      loading: false,
      dialog: false,
      selected: [],
    };
  },
  computed: {
    styleItem() {
      return (path) => {
        let pathUrl = pathImage(path);
        return pathUrl ? { backgroundImage: `url(${pathUrl})` } : null;
      };
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        this.dialog = newVal;
      },
    },
  },
  methods: {
    createRoomCategory() {
      this.loading = true;
      let body = [
        ...new Set([
          ...this.selected,
          ...this.roomQuotaCategories.map((item) => item.category_id),
        ]),
      ].map((catId) => {
        return {
          category_id: catId,
          quota_room_id: this.roomId,
        };
      });

      CategoryService.createQuotaRoomsCategories(body)
        .then(() => {
          this.$emit("createRoomCategory");
          this.$store.commit(
            "snackbarModule/SET_SUCCESS_MESSAGE",
            this.$t("snackbar.success.update")
          );
        })
        .catch(() => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        })
        .finally(() => (this.loader = false));
    },
  },
};
</script>

<style src="./CategoryListDeepTwoModal.scss" lang="scss" scoped></style>